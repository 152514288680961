.headerContainer {
  height: 8rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  box-shadow: 0rem 0rem 0.35rem goldenrod;

  .logoWrapper {
    max-width: 15rem;
    width: 100%;
  }
}
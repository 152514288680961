.footerContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem 1rem;
  background-color: #424242;
  color: white;
  line-height: 1.5rem;
  font-size: 0.75rem;

  .logoWrapper {
    max-width: 12rem;
    width: 100%;
  }

  .copyrightWrapper {
    display: flex;
    align-items: center;
  }
}
.contactDetailContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1rem 0;

  .detailsWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  a, a:hover, a:visited, a:active {
    color: gray;
    text-decoration: none;
    margin: 0 0.5rem;
    user-select: none;
  }

  .copyLinkDiv {
    cursor: pointer;
    color: gray;
    user-select: none;
  }

  .titleWrapper {
    font-weight: bold;
    color: gray;
    width: 7rem;
    text-align: center;
  }

  .link {
    top: -0.25rem;
    position: relative;
    font-size: 0.75rem;
  }

  .checkIcon {
    width: 1rem;
    user-select: none;
  }

  .furtherInfo {
    font-size: 0.75rem;
    color: red;
  }
}